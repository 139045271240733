import React from 'react'
import Navbar from './Navbar'
const Error404 = () => {
  return (
    <>
        <Navbar/>
   <div className='d-flex align-items-center justify-content-center mt-5 pt-5'>
    <h1 className='mt-5 pt-5' style={{fontSize:"240px"}}>404</h1>

   </div>
    </>

  )
}

export default Error404