
import './index.css';
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home2"
import Blog from "./Components/Blog"
import Error404 from './Components/Error404';

const App = () => {
 
  return (
   <>
     <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/news" element={<Blog />} />
        <Route path='*' element={<Error404/>} />
        
        {/* <redirect path="/"/> */}
      </Routes>
   </>

  )
}

export default App