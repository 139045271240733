import React from 'react'


import blogss from './Blogs.json'


const Blog = () => {
   const blogvalue=localStorage.getItem('blogvalue')
  return (
   <>
 <header className="st-site-header st-style1 st-sticky-header ">
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div className="st-main-header-left">
            <a className="" href="/"><b className='' style={{color:"#fec544"}}>KHALID SHABBIR</b></a>
            </div>
            <div class="st-main-header-right">
            <div class="st-nav">
              <ul class="st-nav-list st-onepage-nav">
                <li><a href="/#home" className="st-smooth-move">Home</a></li>
                <li><a href="/#about" className="st-smooth-move">About</a></li>
                <li><a href="/#resume" className="st-smooth-move">Resume</a></li>
                <li><a href="/#portfolio" className="st-smooth-move">Portfolio</a></li>
                <li><a href="/#blog" className="st-smooth-move active">Blog</a></li>
                <li><a href="/#contact" className="st-smooth-move">Contact</a></li>
              </ul>
              <div class="sp-phone">
                <svg viewBox="0 0 513.64 513.64">
                  <g>
                    <g>
                      <path d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z" />
                    </g>
                  </g>
                </svg>
                <a href="https://wa.me/+923022905700" rel="noreferrer"><div className="cursor-pointer sp-phone-no ">+923022905700</div></a>
                  
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </header>
    <section id="blog" className="section sec8 pp-scrollable">
    <div>
                 
                  {blogss.filter(post => post.id===blogvalue ).map(fpost => (
                     
                      <div className="st-content">
                          <div className="st-page-heading st-dynamic-bg" data-src={`assets/img/${fpost.backgroundimg}`}>
                              <div className="container">
                                  <div className="st-page-heading-in text-center">
                                      <h1 className="st-page-heading-title">{fpost.taglin}</h1>
                                      <div className="st-post-label">
                                          <span>By <p>{fpost.postby}</p></span>
                                          <span>{fpost.Date}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>{/* .st-page-heading */}
                          <div className="st-height-b100 st-height-lg-b80" />
                          <div className="container">
                              <div className="row">
                                  <div className="col-lg-10 offset-md-1">
                                      <div className="st-post-details st-style1">
                                          <p>{fpost.paragraph1}</p>
                                          <p>{fpost.paragraph12}</p>
                                          <img className="st-zoom-in" src={`assets/img/${fpost.image2}`} alt="blog1" />
                                          <h2>{fpost.heading2}</h2>
                                          <div className="st-post-info">
                                              <div className="st-post-text">
                                                  <p>{fpost.paragraph2}</p>
                                                  {/* <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.</p>
                                                  <p>Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail.</p>
                                                   */}
                                                  <h2>Blog video post can also added.</h2>
                                                  {/* For Youtube */}
                                                  <div className="embed-responsive embed-responsive-16by9">
                                                      <iframe className="embed-responsive-item" title='Intro Video' width={816} height={459} src={fpost.video} allowFullScreen>
                                                      </iframe>
                                                  </div>
                       
                                                  <p>{fpost.paragraph4}</p>
                                                  {/* <blockquote>Integer accumsan arcu ligula, eget dictum augue egestas sed. Curabitur bibendum, lorem ac dapibus pellentesque, justo lectus bibendum enim.<small>by: <span>Mark Parker</span></small></blockquote> */}
                                                  
                                                 {/* / <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use passage of Lorem Ipsum.</p> */}
                                                  <h2>{fpost.heading4}</h2>
                                                  <p>{fpost.paragraph5}</p>
                                                 
                                                  <div className="st-height-b20 st-height-lg-b20" />
                                                  {/* <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.</p> */}
                                              </div>
                                              <div className="st-height-b35 st-height-lg-b35" />
                                              <div className="st-post-meta">
                                                  <div className="st-post-tages">
                                                      <h4 className="st-post-tage-title">Tags:</h4>
                                                      <p>App React Node Express</p>
                                                  </div>
                                                  <div className="st-post-share">
                                                      <h4 className="st-post-share-title">Follow:</h4>
                                                      <div className="st-post-share-btn-list">
                                                          <a href="https://www.facebook.com/khalid.shabbir.7106" target={"_blank"} rel="noreferrer"><i className="fab fa-facebook-f" /></a>
                                                          <a href="https://twitter.com/code_align" target={"_blank"} rel="noreferrer"><i className="fab fa-twitter" /></a>
                                                          <a href="https://www.behance.net/khalidshabbir" target={"_blank"} rel="noreferrer"><i className="fab fa-behance" /></a>
                                                          <a href="https://www.instagram.com/developby.khalid/" target={"_blank"} rel="noreferrer"><i className="fab fa-instagram" /></a>
                                                          {/* <a href="#"><i className="fab fa-pinterest-p" /></a> */}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="st-height-b60 st-height-lg-b60" />
                                          </div>
                                       
                                      </div>
                                      <div className="st-height-b60 st-height-lg-b60" />
                                     
                                  </div>
                              </div>
                          </div>
                          <div className="st-height-b100 st-height-lg-b80" />
                      </div>
                  ))}
                      {/* Start Footer Seciton */}
                      <div className="st-height-b35 st-height-lg-b35" />
                      <footer>
                          <div className="container">
                              <div className="st-copyright-wrap text-center">
                                  <div className="st-copyright-text">© 2022. Designed and Develop by Khalid Shabbir. All right reserved.</div>
                              </div>
                          </div>
                      </footer>
                      {/* End Footer Seciton */}
                  </div>


      </section>


 



   
   </>
  )
}

export default Blog